import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { DraftGoodsReceiptPOService } from './api/draftGoodsReceiptPO.service';
import { DraftGoodsReceiptPODetailService } from './api/draftGoodsReceiptPODetail.service';
import { DraftGoodsReceiptPOStatusService } from './api/draftGoodsReceiptPOStatus.service';
import { GoodsReceiptPOService } from './api/goodsReceiptPO.service';
import { GoodsReceiptPODetailService } from './api/goodsReceiptPODetail.service';
import { GoodsReceiptPOStatusService } from './api/goodsReceiptPOStatus.service';
import { PurchaseOrderService } from './api/purchaseOrder.service';
import { PurchaseOrderDetailService } from './api/purchaseOrderDetail.service';
import { PurchaseOrderStatusService } from './api/purchaseOrderStatus.service';
import { PurchaseRequestService } from './api/purchaseRequest.service';
import { PurchaseRequestDetailService } from './api/purchaseRequestDetail.service';
import { PurchaseRequestStatusService } from './api/purchaseRequestStatus.service';
import { ReturnGoodsService } from './api/returnGoods.service';
import { ReturnGoodsDetailService } from './api/returnGoodsDetail.service';
import { SeriesService } from './api/series.service';
import { VendorService } from './api/vendor.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    DraftGoodsReceiptPOService,
    DraftGoodsReceiptPODetailService,
    DraftGoodsReceiptPOStatusService,
    GoodsReceiptPOService,
    GoodsReceiptPODetailService,
    GoodsReceiptPOStatusService,
    PurchaseOrderService,
    PurchaseOrderDetailService,
    PurchaseOrderStatusService,
    PurchaseRequestService,
    PurchaseRequestDetailService,
    PurchaseRequestStatusService,
    ReturnGoodsService,
    ReturnGoodsDetailService,
    SeriesService,
    VendorService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
