export * from './aVPGroup.service';
import { AVPGroupService } from './aVPGroup.service';
export * from './adjustment.service';
import { AdjustmentService } from './adjustment.service';
export * from './adjustmentDetail.service';
import { AdjustmentDetailService } from './adjustmentDetail.service';
export * from './branch.service';
import { BranchService } from './branch.service';
export * from './businessSegment.service';
import { BusinessSegmentService } from './businessSegment.service';
export * from './category.service';
import { CategoryService } from './category.service';
export * from './draftGoodsIssuance.service';
import { DraftGoodsIssuanceService } from './draftGoodsIssuance.service';
export * from './draftGoodsIssuanceDetail.service';
import { DraftGoodsIssuanceDetailService } from './draftGoodsIssuanceDetail.service';
export * from './draftGoodsReceipt.service';
import { DraftGoodsReceiptService } from './draftGoodsReceipt.service';
export * from './draftGoodsReceiptDetail.service';
import { DraftGoodsReceiptDetailService } from './draftGoodsReceiptDetail.service';
export * from './goodsIssuance.service';
import { GoodsIssuanceService } from './goodsIssuance.service';
export * from './goodsIssuanceDetail.service';
import { GoodsIssuanceDetailService } from './goodsIssuanceDetail.service';
export * from './goodsReceipt.service';
import { GoodsReceiptService } from './goodsReceipt.service';
export * from './goodsReceiptDetail.service';
import { GoodsReceiptDetailService } from './goodsReceiptDetail.service';
export * from './identity.service';
import { IdentityService } from './identity.service';
export * from './item.service';
import { ItemService } from './item.service';
export * from './itemBarCodeMasterData.service';
import { ItemBarCodeMasterDataService } from './itemBarCodeMasterData.service';
export * from './itemUnitOfMeasure.service';
import { ItemUnitOfMeasureService } from './itemUnitOfMeasure.service';
export * from './itemUnitOfMeasureGroup.service';
import { ItemUnitOfMeasureGroupService } from './itemUnitOfMeasureGroup.service';
export * from './itemUnitOfMeasureGroupDetails.service';
import { ItemUnitOfMeasureGroupDetailsService } from './itemUnitOfMeasureGroupDetails.service';
export * from './itemUnitPrice.service';
import { ItemUnitPriceService } from './itemUnitPrice.service';
export * from './itemWarehouse.service';
import { ItemWarehouseService } from './itemWarehouse.service';
export * from './itemWarehouseBin.service';
import { ItemWarehouseBinService } from './itemWarehouseBin.service';
export * from './itemWarehouseCosting.service';
import { ItemWarehouseCostingService } from './itemWarehouseCosting.service';
export * from './itemWarehouseDetails.service';
import { ItemWarehouseDetailsService } from './itemWarehouseDetails.service';
export * from './itemWarehouseHistory.service';
import { ItemWarehouseHistoryService } from './itemWarehouseHistory.service';
export * from './itemWarehouseHistoryDetail.service';
import { ItemWarehouseHistoryDetailService } from './itemWarehouseHistoryDetail.service';
export * from './mainSegment.service';
import { MainSegmentService } from './mainSegment.service';
export * from './packagingType.service';
import { PackagingTypeService } from './packagingType.service';
export * from './priceList.service';
import { PriceListService } from './priceList.service';
export * from './priceListDetail.service';
import { PriceListDetailService } from './priceListDetail.service';
export * from './productionType.service';
import { ProductionTypeService } from './productionType.service';
export * from './region.service';
import { RegionService } from './region.service';
export * from './stockRequest.service';
import { StockRequestService } from './stockRequest.service';
export * from './stockRequestDetail.service';
import { StockRequestDetailService } from './stockRequestDetail.service';
export * from './transactionType.service';
import { TransactionTypeService } from './transactionType.service';
export * from './unitOfMeasure.service';
import { UnitOfMeasureService } from './unitOfMeasure.service';
export * from './warehouse.service';
import { WarehouseService } from './warehouse.service';
export const APIS = [AVPGroupService, AdjustmentService, AdjustmentDetailService, BranchService, BusinessSegmentService, CategoryService, DraftGoodsIssuanceService, DraftGoodsIssuanceDetailService, DraftGoodsReceiptService, DraftGoodsReceiptDetailService, GoodsIssuanceService, GoodsIssuanceDetailService, GoodsReceiptService, GoodsReceiptDetailService, IdentityService, ItemService, ItemBarCodeMasterDataService, ItemUnitOfMeasureService, ItemUnitOfMeasureGroupService, ItemUnitOfMeasureGroupDetailsService, ItemUnitPriceService, ItemWarehouseService, ItemWarehouseBinService, ItemWarehouseCostingService, ItemWarehouseDetailsService, ItemWarehouseHistoryService, ItemWarehouseHistoryDetailService, MainSegmentService, PackagingTypeService, PriceListService, PriceListDetailService, ProductionTypeService, RegionService, StockRequestService, StockRequestDetailService, TransactionTypeService, UnitOfMeasureService, WarehouseService];
