/**
 * SIDCDatawarehouseSales
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateSalesTransactionPaymentCommand { 
    salesTransactionId?: number;
    salesModeOfPaymentId?: number;
    salesModeOfPaymentCode?: string;
    amount?: number;
    changeAmount?: number;
    checkNumber?: string;
    bankCode?: string;
    checkDate?: Date;
    systemDate?: Date;
    glAccountId?: number;
    glAccountCode?: string;
    glAccountName?: string;
}