export * from './returnFromCustomer.service';
import { ReturnFromCustomerService } from './returnFromCustomer.service';
export * from './returnFromCustomerDetail.service';
import { ReturnFromCustomerDetailService } from './returnFromCustomerDetail.service';
export * from './returnFromCustomerStatus.service';
import { ReturnFromCustomerStatusService } from './returnFromCustomerStatus.service';
export * from './salesModeOfPayment.service';
import { SalesModeOfPaymentService } from './salesModeOfPayment.service';
export * from './salesStatus.service';
import { SalesStatusService } from './salesStatus.service';
export * from './salesTransaction.service';
import { SalesTransactionService } from './salesTransaction.service';
export * from './salesTransactionDetail.service';
import { SalesTransactionDetailService } from './salesTransactionDetail.service';
export * from './salesTransactionPayment.service';
import { SalesTransactionPaymentService } from './salesTransactionPayment.service';
export const APIS = [ReturnFromCustomerService, ReturnFromCustomerDetailService, ReturnFromCustomerStatusService, SalesModeOfPaymentService, SalesStatusService, SalesTransactionService, SalesTransactionDetailService, SalesTransactionPaymentService];
