export * from './draftGoodsReceiptPO.service';
import { DraftGoodsReceiptPOService } from './draftGoodsReceiptPO.service';
export * from './draftGoodsReceiptPODetail.service';
import { DraftGoodsReceiptPODetailService } from './draftGoodsReceiptPODetail.service';
export * from './draftGoodsReceiptPOStatus.service';
import { DraftGoodsReceiptPOStatusService } from './draftGoodsReceiptPOStatus.service';
export * from './goodsReceiptPO.service';
import { GoodsReceiptPOService } from './goodsReceiptPO.service';
export * from './goodsReceiptPODetail.service';
import { GoodsReceiptPODetailService } from './goodsReceiptPODetail.service';
export * from './goodsReceiptPOStatus.service';
import { GoodsReceiptPOStatusService } from './goodsReceiptPOStatus.service';
export * from './purchaseOrder.service';
import { PurchaseOrderService } from './purchaseOrder.service';
export * from './purchaseOrderDetail.service';
import { PurchaseOrderDetailService } from './purchaseOrderDetail.service';
export * from './purchaseOrderStatus.service';
import { PurchaseOrderStatusService } from './purchaseOrderStatus.service';
export * from './purchaseRequest.service';
import { PurchaseRequestService } from './purchaseRequest.service';
export * from './purchaseRequestDetail.service';
import { PurchaseRequestDetailService } from './purchaseRequestDetail.service';
export * from './purchaseRequestStatus.service';
import { PurchaseRequestStatusService } from './purchaseRequestStatus.service';
export * from './returnGoods.service';
import { ReturnGoodsService } from './returnGoods.service';
export * from './returnGoodsDetail.service';
import { ReturnGoodsDetailService } from './returnGoodsDetail.service';
export * from './series.service';
import { SeriesService } from './series.service';
export * from './vendor.service';
import { VendorService } from './vendor.service';
export const APIS = [DraftGoodsReceiptPOService, DraftGoodsReceiptPODetailService, DraftGoodsReceiptPOStatusService, GoodsReceiptPOService, GoodsReceiptPODetailService, GoodsReceiptPOStatusService, PurchaseOrderService, PurchaseOrderDetailService, PurchaseOrderStatusService, PurchaseRequestService, PurchaseRequestDetailService, PurchaseRequestStatusService, ReturnGoodsService, ReturnGoodsDetailService, SeriesService, VendorService];
