/**
 * SIDCDatawarehouseSales
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateSalesTransactionCommand { 
    id?: number;
    transactionDate?: Date;
    idLedger?: number;
    transactionTypeId?: number;
    transactionTypeCode?: string;
    reference?: string;
    crossReference?: string;
    isNoEffectOnInventory?: boolean;
    customerTypeId?: number;
    businessPartnerId?: number;
    businessPartnerCode?: string;
    businessPartnerName?: string;
    glAccountId?: number;
    glAccountCode?: string;
    glAccountName?: string;
    paidToDate?: number;
    total?: number;
    amountTendered?: number;
    interestPaid?: number;
    interestBalance?: number;
    isCancelled?: boolean;
    salesStatusId?: number;
    salesStatusDescription?: string;
    isExtracted?: boolean;
    colaReference?: string;
    mainSegmentId?: number;
    mainSegmentCode?: string;
    businessSegmentId?: number;
    businessSegmentCode?: string;
    branchId?: number;
    branchCode?: string;
    signatory?: string;
    remarks?: string;
    systemDate?: Date;
    seniorDiscount?: number;
    feedsDiscount?: number;
    vat?: number;
    vatExemptSales?: number;
    vatAmount?: number;
    warehouseId?: number;
    warehouseCode?: string;
    lrBatch?: string;
    lrType?: string;
    lrReference?: string;
    kanegoDiscount?: number;
    grossTotal?: number;
    sow?: string;
    parity?: string;
    interestComputed?: number;
    deductionDiscount?: number;
    series?: string;
    lastPaymentDate?: Date;
    isAllowedNoEffectInventory?: boolean;
    isPrinted?: boolean;
    idUser?: string;
    accountNumber?: string;
    terminalNumber?: string;
    minNumber?: string;
    youngCoopId?: number;
    youngCoopCode?: string;
    youngCoopName?: string;
}