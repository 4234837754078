import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ReturnFromCustomerService } from './api/returnFromCustomer.service';
import { ReturnFromCustomerDetailService } from './api/returnFromCustomerDetail.service';
import { ReturnFromCustomerStatusService } from './api/returnFromCustomerStatus.service';
import { SalesModeOfPaymentService } from './api/salesModeOfPayment.service';
import { SalesStatusService } from './api/salesStatus.service';
import { SalesTransactionService } from './api/salesTransaction.service';
import { SalesTransactionDetailService } from './api/salesTransactionDetail.service';
import { SalesTransactionPaymentService } from './api/salesTransactionPayment.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ReturnFromCustomerService,
    ReturnFromCustomerDetailService,
    ReturnFromCustomerStatusService,
    SalesModeOfPaymentService,
    SalesStatusService,
    SalesTransactionService,
    SalesTransactionDetailService,
    SalesTransactionPaymentService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
