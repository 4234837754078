/**
 * SIDCDatawarehouse
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateAdjustmentDetailCommand { 
    adjustmentId?: number;
    itemId?: number;
    itemCode?: string;
    itemDescription?: string;
    categoryId?: number;
    categoryName?: string;
    actualCount?: number;
    runningQuantity?: number;
    variance?: number;
    systemDate?: Date;
    idUser?: string;
    price?: number;
    unitOfMeasureId?: number;
    unitOfMeasureCode?: string;
    total?: number;
    runningValue?: number;
}