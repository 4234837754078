/**
 * SIDCDatawarehouse
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateItemWarehouseHistoryDetailCommand { 
    itemWarehouseHistoryId?: number;
    itemId?: number;
    itemCode?: string;
    unitOfMeasureId?: number;
    unitOfMeasureCode?: string;
    averageCost?: number;
    runningQuantity?: number;
    beginningQuantity?: number;
    minimumQuantity?: number;
    maximumQuantity?: number;
    transactionValue?: number;
    runningValue?: number;
    systemDate?: Date;
}