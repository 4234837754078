/**
 * SIDCDatawarehousePurchasing
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdatePurchaseOrderCommand { 
    id?: number;
    idLedger?: number;
    reference?: string;
    poReference?: string;
    vendorId?: string;
    vendorName?: string;
    vendorAddress?: string;
    vendorCode?: string;
    postingDate?: Date;
    deliveryDate?: Date;
    toWarehouseId?: number;
    toWarehouseCode?: string;
    mainSegmentId?: number;
    mainSegmentCode?: string;
    businessSegmentId?: number;
    businessSegmentCode?: string;
    branchId?: number;
    branchCode?: string;
    remarks?: string;
    cancelled?: number;
    purchaseOrderStatusId?: number;
    purchaseOrderStatusDescription?: string;
    total?: number;
    userId?: string;
}