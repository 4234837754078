/**
 * SIDCDatawarehouseSales
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateSalesTransactionDetailCommand { 
    salesTransactionId?: number;
    barcode?: string;
    itemId?: number;
    itemCode?: string;
    itemDescription?: string;
    unitOfMeasureId?: number;
    unitOfMeasureCode?: string;
    unitOfMeasureDescription?: string;
    quantity?: number;
    cost?: number;
    sellingPrice?: number;
    feedsDiscount?: number;
    total?: number;
    conversion?: number;
    seniorDiscount?: number;
    runningQuantity?: number;
    lineTotal?: number;
    deductionDiscount?: number;
    vat?: number;
    vatable?: number;
    vatExempt?: number;
    cancelledQuantity?: number;
    idUser?: string;
    systemDate?: Date;
}