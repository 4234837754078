import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SporkAPIService {

    private apiUrl: string; // Define apiUrl property

    constructor(private http: HttpClient) {
      this.apiUrl = environment.apiUrlForDWInventory; // Assign apiUrl using environment variable
    }
  
    fetchBarcodeData(barcode: string) {
      // Use apiUrl to construct the API URL
      const url = `${this.apiUrl}/api/v1/inventory/masterdataentries/ItemBarCodeMasterData/barcode?barcode=${barcode}`;
      return this.http.get(url);
    }
  }