/**
 * SIDCDatawarehousePurchasing
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateGoodsReceiptPOCommand { 
    id?: number;
    idLedger?: number;
    reference?: string;
    crossreference?: string;
    purchaseRequestId?: number;
    purchaseRequestReference?: string;
    vendorId?: string;
    vendorName?: string;
    vendorAddress?: string;
    total?: number;
    transactionTypeId?: number;
    transactionTypeCode?: string;
    warehouseId?: number;
    warehouseCode?: string;
    mainSegmentId?: number;
    mainSegmentCode?: string;
    businessSegmentId?: number;
    businessSegmentCode?: string;
    branchId?: number;
    branchCode?: string;
    remarks?: string;
    cancelled?: number;
    transactionDate?: Date;
    systemDate?: Date;
    idUser?: string;
    terminalNumber?: string;
    extracted?: string;
    goodsReceiptPOStatusId?: number;
    goodsReceiptPOStatusDescription?: string;
}