/**
 * SIDCDatawarehousePurchasing
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreatePurchaseOrderDetailCommand { 
    purchaseOrderId?: number;
    reference?: string;
    itemId?: number;
    itemCode?: string;
    itemDescription?: string;
    quantity?: number;
    price?: number;
    total?: number;
    unitOfMeasureId?: number;
    unitOfMeasureCode?: string;
    unitOfMeasureDescription?: string;
    branchId?: number;
    branchName?: string;
    businessSegmentId?: number;
    businessSegmentName?: string;
}